import React from "react";
import ReactModal from "react-modal";
import "./style.css";
import { ReactChildren } from "types/react";
import { defaults } from "theme";
// import { Loader } from "components/Loader";
import { ModalClose } from "./ModalClose";
import { ModalLoadingWrapper } from "./style";

export type ModalProps = {
  isOpen: boolean;
  isLoading?: boolean;
  toggleModal: () => void;
  onAfterOpen?: () => void;
  onAfterClose?: () => void;
  overlayStyle?: React.CSSProperties;
  contentStyle?: React.CSSProperties;
  innerStyle?: React.CSSProperties;
  slide?: boolean;
  "data-testid"?: string;
  children?: ReactChildren;
};

export const Modal = ({
  isOpen = false,
  isLoading,
  toggleModal,
  onAfterOpen,
  onAfterClose,
  overlayStyle,
  contentStyle,
  innerStyle,
  slide,
  "data-testid": dataTestId,
  children,
}: ModalProps) => {
  const duration = 225;

  const handleToggle = () => {
    if (isLoading) return null;
    toggleModal();
  };

  const getClassName = () => {
    let className = "modal";
    if (isLoading) className += " loading";
    if (slide) className += " slide";
    return className;
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={handleToggle}
      onAfterOpen={onAfterOpen}
      onAfterClose={() => {
        if (isOpen) {
          // handle edge cases where the modal closes but the isOpen
          // flag is still true. This can happen if you click the
          // browser back button while the modal is active
          toggleModal();
        }
        if (onAfterClose) onAfterClose();
      }}
      closeTimeoutMS={duration}
      className={getClassName()}
      ariaHideApp={false}
      testId={dataTestId}
      style={{
        overlay: {
          position: "fixed",
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundColor: "rgba(0, 0, 0, 0.5)",
          padding: "100px 0",
          overflowY: "auto",
          display: "flex",
          transition: `opacity ${duration}ms ease-in-out`,
          zIndex: 250,
          ...(slide && {
            padding: 0,
            overflow: "hidden",
          }),
          ...overlayStyle,
        },
        content: {
          position: "relative",
          overflowY: "auto",
          backgroundColor: "rgb(39, 39, 39)",
          borderRadius: defaults.borderRadius,
          padding: 0,
          width: `${defaults.width}px`,
          margin: "0 auto",
          outline: 0,
          border: "0",
          boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.16)",
          ...(slide && {
            margin: "auto auto 0 auto",
            height: "75%",
            boxShadow: "0 0 15px 25px rgba(0, 0, 0, 0.4)",
            transition: `bottom ${duration}ms ease-out`,
          }),
          ...contentStyle,
        },
      }}
    >
      {/* {isLoading ? (
        <Loader
          variant="absolute"
          backdrop="transparent"
          style={{ ...(slide && { top: 200, bottom: "initial" }) }}
        />
      ) : null} */}
      <ModalLoadingWrapper $isLoading={isLoading} style={{ height: "100%" }}>
        {children}
        <ModalClose onClick={handleToggle} />
      </ModalLoadingWrapper>
    </ReactModal>
  );
};

export {
  ModalInner,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalTitle,
  ModalText,
} from "./style";
export { ModalClose } from "./ModalClose";
