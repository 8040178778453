import styled, { css, keyframes } from "styled-components";
import { defaults, colors } from "theme";
import { LoaderProps } from "./index";
import { loaderSizeLarge, loaderSizeSmall } from "./LoaderSpinner/style";

type LoaderStyleProps = {
  $variant: LoaderProps["variant"];
  $backdrop: LoaderProps["backdrop"];
  $size: LoaderProps["size"];
};

export const loaderBackdropTransparentColor = "rgba(0, 0, 0, 0.25)";
export const loaderBackdropOpaqueColor = colors.bg;

function getLoaderSize($size: LoaderProps["size"]) {
  if ($size === "large") {
    return loaderSizeLarge;
  } else {
    return loaderSizeSmall;
  }
}

const LoaderFadeIn = keyframes`
  from {
    opacity:  0;
  }
  to {
    opacity:  1;
  }
`;

export const LoaderContainer = styled.div<LoaderStyleProps>`
  position: ${(props) =>
    (props.$variant === "fixed" && "fixed") ||
    (props.$variant === "fixedCenter" && "fixed") ||
    (props.$variant === "absolute" && "absolute") ||
    "relative"};
  height: ${(props) => (props.$variant === "block" && "100px") || "auto"};
  background: ${(props) =>
    (props.$backdrop === "transparent" && loaderBackdropTransparentColor) ||
    (props.$backdrop === "opaque" && loaderBackdropOpaqueColor) ||
    "none"};
  display: ${(props) => (props.$variant === "inline" && "block") || "flex"};
  z-index: 225;

  ${(props) =>
    (props.$variant === "absolute" ||
      props.$variant === "fixed" ||
      props.$variant === "fixedCenter") &&
    css`
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    `}

  ${(props) =>
    (props.$variant === "block" ||
      props.$variant === "absolute" ||
      props.$variant === "fixed") &&
    css`
      align-items: center;
      justify-content: center;
    `}

  ${(props) =>
    props.$variant === "fixedCenter" &&
    css`
      animation: ${LoaderFadeIn} ${defaults.transition};

      .loader-spinner {
        top: calc(50% - ${getLoaderSize(props.$size) / 2}px);
        left: calc(50% - ${getLoaderSize(props.$size) / 2}px);
      }
    `}
`;
