import ReactSelect, {
  components,
  OnChangeValue,
  MenuProps,
} from "react-select";
import isDeepEqual from "fast-deep-equal/react";
import { FormProps } from "../types";
import { getFieldError } from "utils/form";
import { FormGroup } from "../FormGroup";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";
import { StyledDropdownIndicator, StyledDropdownClear } from "./style";
import { getReactSelectStyle } from "./lib.style";

export type SelectOption = {
  label: string;
  value: string;
};

export type SelectProps = FormProps & {
  options: {
    label: SelectOption["label"];
    value?: SelectOption["value"];
    // optional 'grouped' select
    options?: SelectOption[];
  }[];
  isClearable?: boolean;
  isMulti?: boolean;
  dropdownWidth?: number;
};

export const Select = ({
  options = [],
  id,
  onChange,
  label,
  isClearable,
  isMulti = false,
  dropdownWidth,
  isRequired,
  isLoading,
  disabled,
  readOnly,
  placeholder,
  validationTheme,
  containerStyle,
  form,
  field,
  hasCustomError,
}: SelectProps) => {
  const fieldError = getFieldError({
    form,
    field,
  });
  const getHasError = () => {
    if (hasCustomError) {
      return hasCustomError;
    } else {
      return !!fieldError;
    }
  };
  const hasError = getHasError();

  const handleChange = (option: OnChangeValue<any, false>) => {
    if (isMulti) {
      // isMulti values will recieve an array of objects
      form.setFieldValue(field.name, option);
    } else {
      // regular values will receive a simple object
      form.setFieldValue(field.name, option?.value ?? "");
    }
  };

  const getStandardListValue = (): any => {
    if (typeof field.value === "object") {
      // If field.value is supplied as an object, attempt to match an array element on
      // key/value pairs.
      const objectOptionMatch = options.find((option) => {
        return option.value && isDeepEqual(option.value, field.value);
      });
      return objectOptionMatch || { label: "", value: "" };
    }

    // Default case, expect field.value is a string.
    const defaultOptionMatch = options.find(
      (option) => option.value && option.value === field.value
    );
    return defaultOptionMatch || { label: "", value: "" };
  };

  const getGroupedListValue = (): any => {
    // create an array of all nested option[] values
    const nestedOptions = options.reduce(
      (acc: { value: string; label: string }[], curr) => [
        ...acc,
        ...(curr.options || []),
      ],
      []
    );
    return nestedOptions.find((data) => data.value === field.value);
  };

  const getValue = (): any => {
    if (typeof field.value === "string" && field.value === "") {
      return null;
    } else if (isMulti) {
      return field.value;
    } else {
      const isGroupedList = options.find((data) => Array.isArray(data.options));
      return isGroupedList ? getGroupedListValue() : getStandardListValue();
    }
  };

  const reactSelectStyle = getReactSelectStyle({
    isMulti,
    hasError,
    dropdownWidth,
  });

  return (
    <FormGroup
      isLoading={isLoading}
      style={containerStyle}
      extraClassName="react-select-container"
    >
      {label && (
        <FormLabel htmlFor={id} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}
      <ReactSelect
        inputId={id}
        name={field.name}
        value={getValue()}
        onChange={onChange || handleChange}
        onBlur={field.onBlur}
        placeholder={placeholder || ""}
        options={options}
        styles={reactSelectStyle}
        isSearchable={false}
        components={{
          DropdownIndicator: (props: any) => (
            <StyledDropdownIndicator {...props.innerProps}>
              <span className="material-symbols-outlined">&#xe5cf;</span>
            </StyledDropdownIndicator>
          ),
          ClearIndicator: (props: any) => {
            // note, react-select will attempt to render 'ClearIndicator' if
            // value is an empty string. e.g. { label: "", value: "" }
            const hasValue = props.selectProps?.value?.value;
            if (!hasValue) return <></>;
            return (
              <StyledDropdownClear
                {...props.innerProps}
                // react-select is adding an aria-hidden="true" value for some reason. Override it.
                aria-hidden="false"
                tabIndex="-1"
                aria-label={`Clear${label ? ` ${label}` : ""}`}
                type="button"
              >
                <span className="material-symbols-outlined">&#xe5cd;</span>
              </StyledDropdownClear>
            );
          },
          Menu: (props: MenuProps) => {
            return (
              <div className="react-select-menu" data-testid={`${id}-list`}>
                <components.Menu {...props} />
              </div>
            );
          },
        }}
        // menuPosition="fixed"
        menuPlacement="bottom"
        isDisabled={disabled || readOnly}
        isClearable={isClearable}
        isMulti={isMulti}
      />
      {/* react-select doesn't have support for readOnly. Leverage isDisabled above and add
      a supplementary hidden input for form submission, screen readers and testing. */}
      {readOnly ? (
        <input type="hidden" value={field.value} name={field.name} />
      ) : null}
      {fieldError && (
        <FormError validationTheme={validationTheme}>{fieldError}</FormError>
      )}
    </FormGroup>
  );
};
