import { useState } from "react";
import { Formik, Field } from "formik";
import * as Yup from "yup";
import { Container } from "./style";
import { Notification } from "components/Notification";
import { Button, ButtonContainer } from "components/Button";
import { Select, InputNumber, Textarea } from "components/Form";
import { FormFields } from "../types";
import { LikesOrDislikesValuesRaw } from "./LikesOrDislikesModal/types";
import LikesOrDislikesModal from "./LikesOrDislikesModal";
import { Props } from "./types";

export const validationSchema = Yup.object().shape({
  budget: Yup.string().required("Budget is required"),
});

export function Search({
  getGiftSuggestions,
  isLoadingSuggestions,
  isWebsocketReady,
  configMetaData,
  getLikesDislikes,
  likesDislikesData,
  errorData,
  loadingData,
}: Props) {
  // Store likes & dislikes selections as an object to make it easier to submit it to the API later
  const [likesSelections, setLikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [dislikesSelections, setDislikesSelections] = useState<
    LikesOrDislikesValuesRaw[]
  >([]);
  const [likesModalActive, setLikesModalActive] = useState(false);
  const [dislikesModalActive, setDislikesModalActive] = useState(false);
  const genderOptions = configMetaData?.gender ?? [];
  const locationOptions = configMetaData?.location ?? [];
  const ageOptions = configMetaData?.age ?? [];

  const onSubmit = async (formValues: FormFields) => {
    const uniqueLikesArray: string[] = [];
    likesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueLikesArray.includes(value)) {
          uniqueLikesArray.push(value);
        }
      });
    });

    const uniqueDislikesArray: string[] = [];
    dislikesSelections.forEach((item) => {
      item.key.value.forEach((value) => {
        if (!uniqueDislikesArray.includes(value)) {
          uniqueDislikesArray.push(value);
        }
      });
    });

    getGiftSuggestions({
      queryFields: {
        ...formValues,
        likes: uniqueLikesArray,
        dislikes: uniqueDislikesArray,
      },
    });
  };

  const fieldIdPrefix = "search";
  return (
    <Container>
      <Formik
        enableReinitialize
        initialValues={{
          budget: "",
          who: "",
          about: "",
          location: "Australia",
          age: "",
          likes: "",
          dislikes: "",
        }}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
      >
        {({ handleSubmit, values, setFieldValue, isSubmitting }) => {
          const isLikesDislikesDisabled =
            !values.age.length || !values.location.length || !values.who.length;
          return (
            <form onSubmit={handleSubmit}>
              <Field
                id={`${fieldIdPrefix}-budget`}
                name="budget"
                label="Budget*"
                min={"0"}
                component={InputNumber}
              />
              <Field
                id={`${fieldIdPrefix}-age`}
                name="age"
                label="Age*"
                component={Select}
                options={ageOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                isClearable
                onChange={(
                  option: {
                    value: string;
                  } | null,
                  field: { name: string }
                ) => {
                  setFieldValue(field.name, option ? option.value : "");

                  // Unset previous likes & dislikes values if set
                  if (values.likes) {
                    setFieldValue("likes", "");
                  }
                  if (values.dislikes) {
                    setFieldValue("dislikes", "");
                  }
                }}
              />
              <Field
                id={`${fieldIdPrefix}-who`}
                name="who"
                label="Gender*"
                component={Select}
                options={genderOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                isClearable
                onChange={(
                  option: {
                    value: string;
                  } | null,
                  field: { name: string }
                ) => {
                  setFieldValue(field.name, option ? option.value : "");

                  // Unset previous likes & dislikes values if set
                  if (values.likes) {
                    setFieldValue("likes", "");
                  }
                  if (values.dislikes) {
                    setFieldValue("dislikes", "");
                  }
                }}
              />
              <Field
                id={`${fieldIdPrefix}-location`}
                name="location"
                label="Location*"
                component={Select}
                options={locationOptions.map((option) => ({
                  label: option,
                  value: option,
                }))}
                isClearable
                onChange={(
                  option: {
                    value: string;
                  } | null,
                  field: { name: string }
                ) => {
                  setFieldValue(field.name, option ? option.value : "");

                  // Unset previous likes & dislikes values if set
                  if (values.likes) {
                    setFieldValue("likes", "");
                  }
                  if (values.dislikes) {
                    setFieldValue("dislikes", "");
                  }
                }}
              />
          
              <Field
                id={`${fieldIdPrefix}-about`}
                name="about"
                label="About them (Recommended)"
                tooltip={`Tell us a little about their personality, 
values or anything relevant for 
personalised gift ideas. Example: They work in marketing. They're interested in fashion, travel and fitness.`}
                component={Textarea}
              />
              <Field
                id={`${fieldIdPrefix}-likes`}
                name="likes"
                label="Interests (Optional)"
                tooltip={`Select what they’re interested in from our lists, or type their interests into the About Them field. Less is usually best. The lists will load once you set a gender and age.`}
                component={Textarea}
                onClick={() => {
                  setLikesModalActive(true);
                }}
                disabled={isLikesDislikesDisabled}
                readOnly
              />
              <Field
                id={`${fieldIdPrefix}-dislikes`}
                name="dislikes"
                label="Dislikes (Optional)"
                tooltip={`Select things that they don't like or may
not like from our list. This helps us 
recommend gifts.`}
                component={Textarea}
                onClick={() => {
                  setDislikesModalActive(true);
                }}
                disabled={isLikesDislikesDisabled}
                readOnly
              />

              {/* Popups */}
              <LikesOrDislikesModal
                modalType="LIKES"
                isModalActive={likesModalActive}
                setModalActive={setLikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.likes}
                fieldKey="likes"
                setLikesOrDislikesSelections={setLikesSelections}
                likesOrDislikesSelections={likesSelections}
                locationValue={values.location}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />
              <LikesOrDislikesModal
                modalType="DISLIKES"
                isModalActive={dislikesModalActive}
                setModalActive={setDislikesModalActive}
                setFieldValue={setFieldValue}
                fieldValue={values.dislikes}
                fieldKey="dislikes"
                setLikesOrDislikesSelections={setDislikesSelections}
                likesOrDislikesSelections={dislikesSelections}
                locationValue={values.location}
                genderValue={values.who}
                ageValue={values.age}
                likesDislikesData={likesDislikesData}
                getLikesDislikes={getLikesDislikes}
              />

              {errorData?.result ? (
                <Notification
                  title="Error"
                  message={errorData.result.error}
                  color="red"
                  style={{ margin: "30px 0" }}
                />
              ) : null}

              <ButtonContainer>
                <Button
                  icon="search"
                  type="submit"
                  isLoading={isLoadingSuggestions}
                  loadingData={loadingData}
                  disabled={!isWebsocketReady || isSubmitting}
                >
                  Find Gifts
                </Button>
              </ButtonContainer>
            </form>
          );
        }}
      </Formik>
    </Container>
  );
}
