import styled from "styled-components";
import { defaults, colors } from "theme";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, formElementStyle } from "../style";

export const StyledInput = styled.input<
  FormStyleProps & { $isClearable?: boolean }
>`
  ${formElementBaseStyle};
  padding-right: ${(props) =>
    (props.$isClearable && "30px") || formElementStyle.paddingHorizontal};
`;

export const InputWrapper = styled.div`
  position: relative;
`;

export const InputClearBtn = styled.button`
  background: none;
  border: none;
  padding: 0 8px;
  outline: 0;
  height: 26px;
  display: flex;
  align-items: center;
  cursor: pointer;
  position: absolute;
  right: 0;
  top: 50%;
  margin-top: -13px;
  border-radius: ${defaults.borderRadius}; // focus

  svg {
    fill: ${formElementStyle.iconColor};
    display: block;
    pointer-events: none;
    transition: fill ${defaults.transition};
  }

  &:hover {
    svg {
      fill: ${colors.red};
    }
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }
`;
