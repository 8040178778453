export const SvgNotificationSuccess = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fillRule="evenodd">
      <path d="M15.0552857,12.2506286 C14.2881429,13.9126286 12.9201429,15.1777714 11.2015714,15.8112 C9.48214286,16.4446286 7.62042857,16.3700571 5.95928571,15.6029143 C4.29642857,14.8366286 3.03214286,13.4677714 2.39785714,11.7492 C1.76528571,10.0306286 1.839,8.16891429 2.607,6.50691429 C3.37328571,4.84405714 4.74214286,3.57977143 6.46071429,2.94634286 C7.233,2.66091429 8.03528571,2.51948571 8.83414286,2.51948571 C9.813,2.51948571 10.7875714,2.73205714 11.703,3.15462857 C13.365,3.92091429 14.6301429,5.28977143 15.2635714,7.00834286 C15.8961429,8.72691429 15.8232857,10.5886286 15.0552857,12.2506286 Z M16.8715714,6.4152 C16.0804286,4.26805714 14.4998571,2.55634286 12.4212857,1.5972 C10.3435714,0.639771429 8.01642857,0.546342857 5.86757143,1.33834286 C3.71957143,2.12862857 2.00871429,3.71005714 1.04957143,5.78862857 C0.0904285714,7.8672 -0.00128571429,10.1943429 0.789857143,12.3414857 C1.581,14.4894857 3.16242857,16.2003429 5.241,17.1603429 C6.38442857,17.6874857 7.60328571,17.9523429 8.82642857,17.9523429 C9.82585714,17.9523429 10.8278571,17.7749143 11.7938571,17.4192 C13.9418571,16.6280571 15.6527143,15.0474857 16.6118571,12.9689143 C17.5718571,10.8903429 17.6635714,8.5632 16.8715714,6.4152 Z M7.80557143,11.0574857 L5.67642857,9.21034286 L4.55271429,10.5063429 L8.03528571,13.5252 L13.2184286,6.99805714 L11.8761429,5.93177143 L7.80557143,11.0574857 Z" />
    </g>
  </svg>
);
