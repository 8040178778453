import styled, { keyframes } from "styled-components";
import { LoaderProps } from "../index";

export const loaderSizeLarge = 35;
export const loaderSizeSmall = 30;
const borderWidthLarge = 3;
const borderWidthSmall = 3;

const SpinnerWrapperRotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`;

export const SpinnerContainer = styled.div<{ $size: LoaderProps["size"] }>`
  width: ${(props) =>
    (props.$size === "small" && `${loaderSizeSmall}px`) ||
    `${loaderSizeLarge}px`};
  height: ${(props) =>
    (props.$size === "small" && `${loaderSizeSmall}px`) ||
    `${loaderSizeLarge}px`};
  position: relative;
  animation: ${SpinnerWrapperRotate} 1568ms linear infinite;
`;

const SpinnerRotate = keyframes`
  12.5% {
    transform: rotate(135deg);
  }
  25% {
    transform: rotate(270deg);
  }
  37.5% {
    transform: rotate(405deg);
  }
  50% {
    transform: rotate(540deg);
  }
  62.5% {
    transform: rotate(675deg);
  }
  75% {
    transform: rotate(810deg);
  }
  87.5% {
    transform: rotate(945deg);
  }
  to {
    transform: rotate(1080deg);
  }
`;

export const SpinnerInner = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  border-color: #fff;
  opacity: 1;
  display: flex;
  animation: ${SpinnerRotate} 5332ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;

export const ClipperLeft = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
`;

const LeftSpin = keyframes`
  from {
    transform: rotate(130deg);
  }
  50% {
    transform: rotate(-5deg);
  }
  to {
    transform: rotate(130deg);
  }
`;

export const ClipperLeftCircle = styled.div<{ $size: LoaderProps["size"] }>`
  width: 200%;
  height: 100%;
  border-width: ${(props) =>
    (props.$size === "small" && `${borderWidthSmall}px`) ||
    `${borderWidthLarge}px`};
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border-right-color: transparent;
  transform: rotate(129deg);
  animation: ${LeftSpin} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;

export const Spacer = styled.div`
  position: absolute;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
`;

export const SpacerInner = styled.div`
  width: 1000%;
  left: -450%;
  border-radius: 50%;
`;

export const ClipperRight = styled.div`
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
`;

const RightSpin = keyframes`
  from {
    transform: rotate(-130deg);
  }
  50% {
    transform: rotate(5deg);
  }
  to {
    transform: rotate(-130deg);
  }
`;

export const ClipperRightCircle = styled.div<{ $size: LoaderProps["size"] }>`
  width: 200%;
  height: 100%;
  border-width: ${(props) =>
    (props.$size === "small" && `${borderWidthSmall}px`) ||
    `${borderWidthLarge}px`};
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -100%;
  border-left-color: transparent;
  transform: rotate(-129deg);
  animation: ${RightSpin} 1333ms cubic-bezier(0.4, 0, 0.2, 1) infinite both;
`;
