import {
  GiftSuggestionsResponse,
  GiftSuggestionResponse,
  FormMetaDataResponse,
  FormMetaDataItemResponse,
  ConfigMetaDataResponse,
  LikesDislikesDataResponse,
  ErrorResponse,
  LoadingResponse,
} from "./types";

export function capitaliseFirstLetter(string: string) {
  if (string.length) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } else {
    return string;
  }
}

export function isGiftSuggestion(obj: any): obj is GiftSuggestionResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "group" in obj &&
    "results" in obj &&
    Array.isArray(obj.results)
  );
}

export function isGiftSuggestionsArray(
  lastMsg: unknown
): lastMsg is GiftSuggestionsResponse {
  return (
    typeof lastMsg === "object" &&
    lastMsg !== null &&
    "results" in lastMsg &&
    Array.isArray(lastMsg.results) &&
    lastMsg.results.every(isGiftSuggestion)
  );
}

export function isFormMetaDataItem(obj: any): obj is FormMetaDataItemResponse {
  return (
    typeof obj === "object" && obj !== null && "age" in obj && "results" in obj
  );
}

export function isFormMetaData(obj: any): obj is FormMetaDataResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "men" in obj &&
    Array.isArray(obj.men) &&
    obj.men.length > 0 &&
    isFormMetaDataItem(obj.men[0])
  );
}

export function isConfigMetaData(obj: any): obj is ConfigMetaDataResponse {
  if (typeof obj === "object" && obj !== null) {
    if (
      "age" in obj ||
      "gender" in obj ||
      "location" in obj ||
      "occasion" in obj ||
      "relationship" in obj
    ) {
      return true;
    }
  }
  return false;
}

export function isLikesDislikesData(
  obj: any
): obj is LikesDislikesDataResponse {
  if (typeof obj === "object" && obj !== null) {
    if ("standard" in obj || "likes" in obj) {
      return true;
    }
  }
  return false;
}

export function isErrorData(obj: any): obj is ErrorResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "result" in obj &&
    "response" in obj &&
    obj.response === "error" &&
    typeof obj.result === "object" &&
    obj.result !== null &&
    "error" in obj.result
  );
}

export function isMale(who: string) {
  if (who === "Man" || who === "Boy" || who === "Teen Boy") {
    return true;
  } else {
    return false;
  }
}

export function isFemale(who: string) {
  if (who === "Woman" || who === "Girl" || who === "Teen Girl") {
    return true;
  } else {
    return false;
  }
}

export function isLoadingResponseData(obj: any): obj is LoadingResponse {
  return (
    typeof obj === "object" &&
    obj !== null &&
    "progress" in obj &&
    typeof obj.progress === "number"
  );
}
