export const SvgNotificationError = () => {
  return (
    <svg
      width="17"
      height="18"
      viewBox="0 0 17 18"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd">
        <path d="M8.11630227,15.7795057 C6.70984773,15.7795057 5.41384773,15.3123239 4.36412045,14.5317784 C5.65684773,13.427233 8.10975682,10.9129602 13.1268477,5.63896023 C13.9401205,6.70259659 14.4302114,8.02641477 14.4302114,9.46559659 C14.4302114,12.9469602 11.5976659,15.7795057 8.11630227,15.7795057 M1.80239318,9.46559659 C1.80239318,5.98423295 4.63493864,3.1516875 8.11630227,3.1516875 C9.568575,3.1516875 10.9046659,3.65077841 11.9732114,4.47796023 C8.38466591,8.24241477 4.51384773,12.203233 3.18593864,13.3994148 C2.32275682,12.3194148 1.80239318,10.9530511 1.80239318,9.46559659 M8.11630227,1.51532386 C3.73330227,1.51532386 0.166029545,5.08259659 0.166029545,9.46559659 C0.166029545,13.8494148 3.73330227,17.4158693 8.11630227,17.4158693 C12.4993023,17.4158693 16.066575,13.8494148 16.066575,9.46559659 C16.066575,5.08259659 12.4993023,1.51532386 8.11630227,1.51532386" />
      </g>
    </svg>
  );
};
