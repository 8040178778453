import { ReactStyle } from "types/react";
import { TooltipIcon as StyledTooltipIcon } from "./style";
import { SvgTooltipInfo } from "svg/SvgTooltipInfo";

export type TooltipIconProps = {
  style?: ReactStyle;
  onClick?: () => void;
  iconType?: "question" | "info";
};

export const TooltipIcon = ({
  iconType = "info",
  ...rest
}: TooltipIconProps) => {
  return (
    <StyledTooltipIcon
      type="button"
      tabIndex={-1}
      $iconType={iconType}
      {...rest}
    >
      {iconType === "info" ? <SvgTooltipInfo /> : null}
      {iconType === "question" ? "?" : null}
    </StyledTooltipIcon>
  );
};
