import styled from "styled-components";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, formElementStyle } from "../style";

export const StyledTextarea = styled.textarea<FormStyleProps>`
  ${formElementBaseStyle};
  line-height: ${formElementStyle.textareaLineHeight};
  padding-top: 6px;
  padding-bottom: 6px;
  height: ${formElementStyle.textareaHeight};
  resize: vertical;
  min-height: ${formElementStyle.textareaHeight};
  max-height: ${formElementStyle.textareaMaxHeight};
`;
