export const SvgNotificationInfo = () => {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g fillRule="evenodd">
        <path d="M9.32885714,16.2646821 C5.68171429,16.2646821 2.71428571,13.2972536 2.71428571,9.65011071 C2.71428571,6.00296786 5.68171429,3.03639643 9.32885714,3.03639643 C12.976,3.03639643 15.9434286,6.00296786 15.9434286,9.65011071 C15.9434286,13.2972536 12.976,16.2646821 9.32885714,16.2646821 Z M9.32885714,1.32211071 C4.73714286,1.32211071 1,5.05839643 1,9.65011071 C1,14.2426821 4.73714286,17.9789679 9.32885714,17.9789679 C13.9205714,17.9789679 17.6577143,14.2426821 17.6577143,9.65011071 C17.6577143,5.05839643 13.9205714,1.32211071 9.32885714,1.32211071 Z M8.47171429,13.4403964 L10.186,13.4403964 L10.186,8.54268214 L8.47171429,8.54268214 L8.47171429,13.4403964 Z M9.31257143,7.70353929 C9.78142857,7.70353929 10.1611429,7.32296786 10.1611429,6.85411071 C10.1611429,6.38696786 9.78142857,6.00639643 9.31257143,6.00639643 C8.84542857,6.00639643 8.46485714,6.38696786 8.46485714,6.85411071 C8.46485714,7.32296786 8.84542857,7.70353929 9.31257143,7.70353929 Z" />
      </g>
    </svg>
  );
};
