import { Helmet } from "react-helmet";
import { metaTitle } from "utils/constants";

export function MetaTitle() {
  return (
    <Helmet>
      <title>{metaTitle}</title>
    </Helmet>
  );
}
