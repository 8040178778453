import styled, { keyframes } from "styled-components";
import { defaults } from "theme";

const TooltipFadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const tooltipBubbleColor = "rgb(70, 70, 70)";
const tooltipBubbleBorderColor = "rgb(70, 70, 70);";
export const TooltipBubbleContainer = styled.div`
  background-color: ${tooltipBubbleColor};
  padding: 15px;
  border-radius: ${defaults.borderRadius};
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
  text-align: center;
  min-width: 200px;
  max-width: 315px;
  animation-name: ${TooltipFadeIn};
  animation-duration: ${defaults.transition};
  animation-timing-function: ease;
  animation-fill-mode: forwards;

  &[data-popper-placement^="top"] > .tooltipArrow {
    bottom: -4px;

    &::before {
      border-right-color: ${tooltipBubbleBorderColor};
      border-bottom-color: ${tooltipBubbleBorderColor};
      border-top-color: ${tooltipBubbleColor};
      border-left-color: ${tooltipBubbleColor};
    }
  }

  &[data-popper-placement^="bottom"] > .tooltipArrow {
    top: -4px;

    &::before {
      border-right-color: ${tooltipBubbleColor};
      border-bottom-color: ${tooltipBubbleColor};
      border-top-color: ${tooltipBubbleBorderColor};
      border-left-color: ${tooltipBubbleBorderColor};
    }
  }

  &[data-popper-placement^="left"] > .tooltipArrow {
    right: -4px;

    &::before {
      border-right-color: ${tooltipBubbleBorderColor};
      border-bottom-color: ${tooltipBubbleColor};
      border-top-color: ${tooltipBubbleBorderColor};
      border-left-color: ${tooltipBubbleColor};
    }
  }

  &[data-popper-placement^="right"] > .tooltipArrow {
    left: -4px;

    &::before {
      border-right-color: ${tooltipBubbleColor};
      border-bottom-color: ${tooltipBubbleBorderColor};
      border-top-color: ${tooltipBubbleColor};
      border-left-color: ${tooltipBubbleBorderColor};
    }
  }
`;

export const TooltipArrow = styled.div`
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
  visibility: hidden;

  &::before {
    content: "";
    visibility: visible;
    transform: rotate(45deg);
    position: absolute;
    left: 0;
    width: 6px;
    height: 6px;
    background: inherit;
    border-style: solid;
    border-width: 1px;
  }
`;

export const TooltipText = styled.p`
  font-family: ${defaults.fontFamily};
  font-size: 13px;
  font-weight: 500;
  line-height: 1.5;
  color: #fff;
  text-transform: none;
  white-space: break-spaces;
  letter-spacing: 0;
  font-weight: 500;
  margin: 0;
`;
