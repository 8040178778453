import { SvgLogo } from "svg/SvgLogo";
import { Container, Link, LogoContainer, Version  } from "./style";
import { appVersion } from "utils/constants";

export function Header() {
  return (
    <Container>
      <LogoContainer>
        <SvgLogo />
        <Version>{appVersion}</Version>
      </LogoContainer>
      <Link href="https://www.givving.co" target="_blank">
        Home
      </Link>
    </Container>
  );
}
