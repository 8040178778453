import { getIn } from "formik";
import { FormProps } from "components/Form/types";

export const getFieldError = ({
  form,
  field,
}: {
  form: FormProps["form"];
  field: FormProps["field"];
}) => {
  if (form && field) {
    const { errors, touched, submitCount } = form;
    const { name } = field;

    if (submitCount > 0) {
      const fieldTouched = getIn(touched, name);
      const fieldError = getIn(errors, name);
      return fieldError && fieldTouched ? fieldError : false;
    }
  }

  return false;
};
