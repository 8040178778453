import { LoaderProps } from "../index";
import {
  SpinnerContainer,
  SpinnerInner,
  ClipperLeft,
  ClipperLeftCircle,
  Spacer,
  SpacerInner,
  ClipperRight,
  ClipperRightCircle,
} from "./style";

export const LoaderSpinner = ({ size }: LoaderProps) => {
  return (
    <SpinnerContainer $size={size} className="loader-spinner">
      <SpinnerInner>
        <ClipperLeft>
          <ClipperLeftCircle $size={size} />
        </ClipperLeft>
        <Spacer>
          <SpacerInner />
        </Spacer>
        <ClipperRight>
          <ClipperRightCircle $size={size} />
        </ClipperRight>
      </SpinnerInner>
    </SpinnerContainer>
  );
};
