import { useState } from "react";
import { usePopper } from "react-popper";
import { ReactStyle, ReactChildren } from "types/react";
import { TooltipIcon } from "./TooltipIcon";
import { TooltipBubble } from "./TooltipBubble";

export type TooltipProps = {
  message: string;
  placement?: "top" | "right" | "bottom" | "left";
  style?: ReactStyle;
  customBubbleStyle?: ReactStyle;
  customXOffset?: number;
  children?: ReactChildren;
};

export const Tooltip = ({
  message,
  placement = "top",
  customBubbleStyle,
  customXOffset,
  children,
  ...rest
}: TooltipProps) => {
  const [visible, setVisible] = useState(false);
  const [referenceElement, setReferenceElement] =
    useState<HTMLDivElement | null>(null);
  const [popperElement, setPopperElement] = useState<HTMLDivElement | null>(
    null
  );
  const [arrowElement, setArrowElement] = useState<HTMLDivElement | null>(null);
  const xOffset = typeof customXOffset === "number" ? customXOffset : 12;
  const { styles, attributes } = usePopper(referenceElement, popperElement, {
    placement,
    strategy: "absolute",
    modifiers: [
      {
        name: "offset",
        options: {
          offset: [0, xOffset],
        },
      },
      {
        name: "flip",
        enabled: false, // disable auto placement
      },
      { name: "arrow", options: { element: arrowElement } },
    ],
  });

  const handleMouseEnter = () => setVisible(true);
  const handleMouseLeave = () => setVisible(false);

  return (
    <>
      <div
        {...rest}
        ref={setReferenceElement}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        className="tooltip"
      >
        {children ? children : <TooltipIcon iconType="question" />}
      </div>
      {visible && message ? (
        <TooltipBubble
          popperRef={setPopperElement}
          arrowRef={setArrowElement}
          styles={styles}
          attributes={attributes}
          message={message}
          customBubbleStyle={customBubbleStyle}
        />
      ) : null}
    </>
  );
};
