import { ReactChildren } from "types/react";
import { Tooltip } from "components/Tooltip";
import {
  StyledFormLabel,
  FormLabelContainer,
  FormLabelAsterisk,
  FormLabelTooltip,
} from "./style";

export type FormLabelProps = React.LabelHTMLAttributes<HTMLLabelElement> & {
  htmlFor?: string;
  isRequired?: boolean;
  tooltip?: string;
  children: ReactChildren;
  onClick?: () => void;
  as?: React.ElementType;
};

export const FormLabel = ({
  htmlFor,
  isRequired,
  tooltip,
  children,
  ...rest
}: FormLabelProps) => {
  return (
    <FormLabelContainer>
      <StyledFormLabel htmlFor={htmlFor} {...rest}>
        {children}
        {isRequired ? <FormLabelAsterisk>&nbsp;*</FormLabelAsterisk> : null}
      </StyledFormLabel>
      {tooltip && (
        <FormLabelTooltip>
          <Tooltip message={tooltip} />
        </FormLabelTooltip>
      )}
    </FormLabelContainer>
  );
};
