import React from "react";
import { FormProps } from "../types";
import { getFieldError } from "utils/form";
import { FormGroup } from "../FormGroup";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";
import { StyledTextarea } from "./style";

export type TextareaProps = React.TextareaHTMLAttributes<HTMLTextAreaElement> &
  FormProps;

export const Textarea = ({
  id,
  onChange,
  label,
  isRequired,
  isLoading,
  tooltip,
  theme,
  validationTheme,
  containerStyle,
  form,
  field,
  ...rest
}: TextareaProps) => {
  const fieldError = getFieldError({
    form,
    field,
  });
  return (
    <FormGroup isLoading={isLoading} style={containerStyle}>
      {label && (
        <FormLabel htmlFor={id} isRequired={isRequired} tooltip={tooltip}>
          {label}
        </FormLabel>
      )}
      <StyledTextarea
        {...field}
        id={id}
        onChange={onChange || field.onChange}
        $hasError={fieldError ? true : false}
        $theme={theme}
        {...rest}
      />
      {fieldError && (
        <FormError validationTheme={validationTheme}>{fieldError}</FormError>
      )}
    </FormGroup>
  );
};
