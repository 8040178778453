import { BrowserRouter, Routes as RRDRoutes, Route } from "react-router-dom";
import GiftSuggestionsV1 from "pages/GiftSuggestions/V1";
import GiftSuggestionsV2 from "pages/GiftSuggestions/V2";
import Error from "pages/Error";

function Routes() {
  return (
    <BrowserRouter>
      <RRDRoutes>
        <Route path="/" element={<GiftSuggestionsV2 />} />
        <Route path="/v1" element={<GiftSuggestionsV1 />} />
        <Route path="*" element={<Error />} />
      </RRDRoutes>
    </BrowserRouter>
  );
}

export default Routes;
