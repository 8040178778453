import styled from "styled-components";
import { defaults, colors } from "theme";

export const Container = styled.div`
  background-color: ${colors.field};
  margin: 0 0 ${defaults.margin};
  padding: ${defaults.padding};
  border-radius: ${defaults.borderRadius};
`;

export const Heading = styled.h2`
  font-size: 24px;
  margin: 0;
  text-align: center;
  color: ${colors.text};
  margin: 0 0 30px;
`;

export const Result = styled.div`
  & + & {
    margin-top: 45px;
    padding-top: 25px;
    border-top: 1px solid rgb(110, 110, 110);
  }
`;

export const ResultHeading = styled.h3`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.text};
  margin: 0 0 25px 0;
`;

export const ResultSubHeading = styled.h4`
  font-size: 16px;
  font-weight: 700;
  color: rgb(175, 171, 161);
  margin: 0 0 8px 0;
`;

export const ResultText = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: ${colors.text};
  margin: 0 0 30px 0;
`;

export const ResultImgContainer = styled.div`
  border-radius: ${defaults.borderRadius};
  overflow: hidden;
  margin-bottom: 25px;
  background: rgba(255, 255, 255, 0.1);

  img {
    width: 100%;
    height: auto;
    display: block;
  }
`;

export const ResultCta = styled.div``;

export const ResultCtaHeading = styled.h4`
  font-size: 20px;
  font-weight: 700;
  color: ${colors.text};
  margin: 0 20px 0 0;

  span {
    font-weight: 400;
  }
`;
