import { MetaTitle } from "components/MetaTitle";
import { ButtonContainer } from "components/Button";
import { ButtonLink } from "components/ButtonLink";
import { LoginContainer, LoginInner, LoginTitle } from "./style";

function Error() {
  return (
    <>
      <MetaTitle />
      <LoginContainer>
        <LoginInner>
          <LoginTitle>Page not found</LoginTitle>
          <ButtonContainer>
            <ButtonLink href="/">Home</ButtonLink>
          </ButtonContainer>
        </LoginInner>
      </LoginContainer>
    </>
  );
}

export default Error;
