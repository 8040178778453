import { StyledToggle, ToggleOption } from "./style";
import { ToggleProps } from "./types";

export const Toggle = ({ options, style }: ToggleProps) => {
  return (
    <StyledToggle style={style}>
      {options.map((option) => (
        <ToggleOption
          key={option.key}
          onClick={option.onClick}
          $isActive={option.isActive}
          type="button"
        >
          {option.label}
        </ToggleOption>
      ))}
    </StyledToggle>
  );
};
