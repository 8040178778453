import styled from "styled-components";
import { colors, defaults } from "theme";

export const LoginContainer = styled.div`
  height: 100vh;
  display: flex;
  align-items: center;
  max-width: ${defaults.width}px;
  margin: 0 auto;
`;

export const LoginInner = styled.div`
  background-color: ${colors.field};
  padding: ${defaults.padding};
  border-radius: ${defaults.borderRadius};
  width: 100%;
`;

export const LoginTitle = styled.h1`
  font-size: 28px;
  font-weight: 700;
  margin: 0;
  text-align: center;
  color: ${colors.text};

  span {
    font-weight: 400;
  }
`;
