import styled from "styled-components";
import { NotificationProps } from "./index";
import { defaults, colors } from "theme";

type NotificationStyleProps = {
  $color?: NotificationProps["color"];
};

export const notificationLgFontSize = "14px";
export const NotificationWrapper = styled.div<NotificationStyleProps>`
  outline: 0;
  border: 0;
  border-style: solid;
  border-width: 1px;
  border-radius: 3px;
  position: relative;
  border-color: ${(props) =>
    (props.$color === "green" && colors.green) ||
    (props.$color === "orange" && colors.orange) ||
    (props.$color === "red" && colors.red) ||
    "#DBDBDB"};
  padding: 22px 25px 25px;
  border-left-width: 5px;
  margin-bottom: 30px;
`;

export const NotificationTitle = styled.h2<NotificationStyleProps>`
  font-family: ${defaults.fontFamily};
  font-size: 13px;
  color: ${colors.text};
  line-height: ${defaults.lineHeight};
  font-weight: 500;
  white-space: break-spaces;
  padding: 0 0 0 25px;
  margin: 0;
  position: relative;

  svg {
    margin-right: 10px;
    display: block;
    position: absolute;
    left: 0;
    top: 2px;
    fill: ${(props) =>
      (props.$color === "green" && colors.green) ||
      (props.$color === "orange" && colors.orange) ||
      (props.$color === "red" && colors.red) ||
      "#BAB9C6"};
  }
`;

export const NotificationMessage = styled.p`
  font-family: ${defaults.fontFamily};
  font-size: 13px;
  font-weight: 400;
  margin: 10px 0 0;
  color: ${colors.text};
  word-wrap: break-word;
`;
