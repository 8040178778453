export const colors = {
  bg: "rgb(17, 17, 17)",
  field: "rgb(39, 39, 39)",
  btn: "rgb(63, 89, 228)",
  btnHover: "rgb(52, 73, 186)",
  btnText: "rgb(255, 255, 255)",
  btnHoverText: "rgb(255, 255, 255)",
  text: "rgb(250, 250, 250)",
  textLight: "rgb(175, 171, 161)",
  formLabel: "rgb(175, 171, 161)",
  border: "rgb(110, 110, 110)",
  green: "#ADD110",
  orange: "#FF902A",
  red: "#FC2222",
  listHover: "rgba(0, 0, 0, 0.2)",
  blue: "rgb(63, 89, 228)",
  blueHover: "rgb(52, 73, 186)",
};
