export const defaults = {
  fontFamily: "'Manrope', sans-serif",
  fontSize: "16px",
  lineHeight: 1.8,
  fontWeight: 400,
  borderRadius: "5px",
  containerWidth: 1180,
  transition: "150ms",
  transitionFull: "all 0.25s cubic-bezier(0.4, 0, 0.2, 1) 0s",
  padding: "25px",
  margin: "15px",
  focusBoxShadow: "0 0 0 2px #5151C8",
  width: 600,
};
