import styled from "styled-components";
import { colors, defaults } from "theme";

export const Container = styled.div`
  background-color: ${colors.field};
  margin: ${defaults.margin} 0;
  padding: ${defaults.padding};
  padding-bottom: 24px;
  border-radius: ${defaults.borderRadius};
  border-bottom: 3px solid;
  border-image-slice: 1;
  border-image-source: linear-gradient(
    90deg,
    rgb(242, 172, 132) 0%,
    rgb(63, 89, 228) 52.6%,
    rgb(64, 22, 160) 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between; /* Ensures space between the logo and the link */
  position: relative;
  height: 104px;
`;


export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start; /* Aligns the logo to the left */
`;

export const Version = styled.p`
  font-size: 26px;
  font-weight: 400;
  margin: 0 0 0 10px;
  padding-bottom: 6px;
  color: ${colors.text};
`;

export const Link = styled.a`
  color: #697CF7;
  text-decoration: underline;
  font-size: 18px;
  position: absolute;
  right: 0; /* Aligns the link to the right */
 transform: translateX(-50%); /* Centers the link within its container */
`;
