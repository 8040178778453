import styled from "styled-components";
import { formElementStyle } from "components/Form/style";
import { defaults, colors } from "theme";
import { TooltipIconProps } from "./index";

export const TooltipIcon = styled.button<{
  $iconType: TooltipIconProps["iconType"];
}>`
  font-size: 11px;
  font-weight: 700;
  color: ${colors.formLabel};
  border-style: solid;
  border-width: 1px;
  border-color: ${colors.border};
  background-color: transparent;
  margin: 3px 0 0;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  outline: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-grow: 0;
  flex-shrink: 0;
  cursor: pointer;
  transition: color ${defaults.transition},
    background-color ${defaults.transition}, border-color ${defaults.transition};

  svg {
    // Note: iconType = "question" isn't an svg
    fill: ${formElementStyle.labelColor};
    transition: fill ${defaults.transition};
  }

  &:hover {
    color: #fff;
    border-color: #fff;

    svg {
      fill: #fff;
    }
  }
`;
