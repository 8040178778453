import styled from "styled-components";
import { defaults } from "theme";
import { formElementStyle } from "../style";

export const StyledDropdownIndicator = styled.div`
  padding: 0 ${formElementStyle.paddingHorizontal};
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 1px;
  padding-left: 8px;

  span {
    font-variation-settings: "OPSZ" 20, "wght" 200;
  }
`;

export const StyledDropdownClear = styled.button`
  background: none;
  border: none;
  padding: 0;
  outline: 0;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: ${defaults.borderRadius}; // focus
  transition: color ${defaults.transition};
  color: #fff;

  span {
    font-variation-settings: "OPSZ" 20, "wght" 200;
    display: block;
  }

  &:hover {
    color: rgba(255, 255, 255, 0.5);
  }

  &:focus {
    box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.15);
  }
`;
