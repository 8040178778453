import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "./style.css";
import { ButtonContainer } from "components/Button";
import { ButtonLink } from "components/ButtonLink";
import { Img } from "components/Img";
import {
  GiftSuggestionResultsResponse,
  GiftSuggestionResultsWithId,
} from "../types";
import {
  Container,
  Heading,
  Result,
  ResultHeading,
  ResultGroupHeading,
  ResultTag,
  ResultText,
  ResultImgContainer,
  ResultCta,
  ResultCtaHeading,
} from "./style";
import { Props } from "./types";

export function Results({
  giftSuggestions,
  sendGiftBuyNowNotification,
}: Props) {
  function getDeliveryValue(
    delivery: GiftSuggestionResultsResponse["delivery"]
  ) {
    if (typeof delivery === "string") {
      return delivery === "Free" || delivery === "Unknown"
        ? delivery
        : `$${delivery}`;
    } else if (typeof delivery === "number") {
      return `$${delivery}`;
    } else {
      return "";
    }
  }

  function onClickBuyNow({ title, link }: { title: string; link: string }) {
    console.log("onClickBuyNow");
    sendGiftBuyNowNotification({ title, link });
  }

  function renderResultItem({
    result,
    resultNum,
    resultsTotalNum,
    group,
  }: {
    result: GiftSuggestionResultsWithId;
    resultNum: number;
    resultsTotalNum: number;
    group?: string;
  }) {
    const title = result.title ?? "";
    const seller = result.seller;
    const description = result.description;
    const link = result.link ?? "";
    const thumbnail = result.thumbnail;
    const price = result.price;
    const delivery = result.delivery;

    return (
      <SwiperSlide key={result.id}>
        <ResultGroupHeading>
          {group}
          {resultsTotalNum > 1 ? ` | Option ${resultNum}` : ""}
        </ResultGroupHeading>
        <ResultHeading>{title}</ResultHeading>
        <ResultTag>{seller}</ResultTag>
        {thumbnail ? (
          <ResultImgContainer>
            <Img src={thumbnail} alt={title} />
          </ResultImgContainer>
        ) : null}
        <ResultText>{description}</ResultText>
        {typeof price === "number" || delivery ? (
          <ResultCta>
            {typeof price === "number" ? (
              <ResultCtaHeading>
                <span>Price:</span> ${price.toString()}
              </ResultCtaHeading>
            ) : null}
            {delivery ? (
              <ResultCtaHeading>
                <span>Delivery:</span> {getDeliveryValue(delivery)}
              </ResultCtaHeading>
            ) : null}
          </ResultCta>
        ) : null}
        {link ? (
          <ButtonContainer>
            <ButtonLink
              href={link}
              onClick={() =>
                onClickBuyNow({
                  title,
                  link,
                })
              }
              target="_blank"
            >
              View Now
            </ButtonLink>
          </ButtonContainer>
        ) : null}
      </SwiperSlide>
    );
  }

  if (giftSuggestions.length) {
    return (
      <Container>
        <Heading>Gift Recommendations</Heading>
        {giftSuggestions.map((giftSuggestion) => (
          <Result key={giftSuggestion.id}>
            <Swiper navigation={true} modules={[Navigation]} autoHeight={true}>
              {giftSuggestion.results?.map((result, i) =>
                renderResultItem({
                  result,
                  resultNum: i + 1,
                  resultsTotalNum: giftSuggestion.results?.length ?? 0,
                  group: giftSuggestion.group,
                })
              )}
            </Swiper>
          </Result>
        ))}
      </Container>
    );
  } else {
    return null;
  }
}
