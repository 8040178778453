import type { ReactStyle } from "types/react";
import { usePopper } from "react-popper";
import { TooltipBubbleContainer, TooltipText, TooltipArrow } from "./style";

type UsePopperType = ReturnType<typeof usePopper>;
type TooltipRef = React.Dispatch<React.SetStateAction<HTMLDivElement | null>>;
export type TooltipBubbleProps = {
  popperRef: TooltipRef;
  arrowRef: TooltipRef;
  message: string;
  styles: UsePopperType["styles"];
  attributes: UsePopperType["attributes"];
  customBubbleStyle?: ReactStyle;
};

export const TooltipBubble = ({
  popperRef,
  arrowRef,
  styles,
  attributes,
  message,
  customBubbleStyle,
}: TooltipBubbleProps) => {
  return (
    <TooltipBubbleContainer
      ref={popperRef}
      style={{ ...styles.popper, ...customBubbleStyle }}
      {...attributes.popper}
      className="tooltipBubble"
    >
      <TooltipText>{message}</TooltipText>
      <TooltipArrow
        ref={arrowRef}
        className="tooltipArrow"
        style={styles.arrow}
      />
    </TooltipBubbleContainer>
  );
};
