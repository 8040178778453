export const SvgModalClose = () => {
  return (
    <svg width={23} height={23} fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.338 5.707a.8.8 0 011.131 0l9.9 9.9a.8.8 0 01-1.131 1.13l-9.9-9.899a.8.8 0 010-1.131z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.369 5.707a.8.8 0 010 1.131l-9.9 9.9a.8.8 0 01-1.131-1.132l9.9-9.9a.8.8 0 011.13 0z"
      />
    </svg>
  );
};
