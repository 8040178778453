import React from "react";
import RcInputNumber from "rc-input-number";
import { FormProps } from "../types";
import { getFieldError } from "utils/form";
import { FormGroup } from "../FormGroup";
import { FormLabel } from "../FormLabel";
import { FormError } from "../FormError";
import { InputNumberWrapper } from "./style";

type InputNumberProps = React.InputHTMLAttributes<HTMLInputElement> &
  FormProps & {
    min?: string;
  };

const IncrementUp = () => {
  return (
    <span className="increment-icon material-symbols-outlined">&#xe5ce;</span>
  );
};

const IncrementDown = () => {
  return (
    <span className="increment-icon material-symbols-outlined">&#xe5cf;</span>
  );
};

export const InputNumber = ({
  id,
  label,
  isRequired,
  isLoading,
  min,
  validationTheme,
  containerStyle,
  field,
  form,
}: InputNumberProps) => {
  const fieldError = getFieldError({
    form,
    field,
  });

  const getHasError = () => {
    return !!fieldError;
  };
  const hasError = getHasError();

  function handleOnChange(value: string | number | null) {
    if (value !== null) {
      form.setFieldValue(
        field.name,
        typeof value === "number" ? value.toString() : value
      );
    }
  }

  return (
    <FormGroup
      isLoading={isLoading}
      style={{
        ...containerStyle,
      }}
    >
      {label && (
        <FormLabel htmlFor={id} isRequired={isRequired}>
          {label}
        </FormLabel>
      )}
      <InputNumberWrapper>
        <RcInputNumber
          id={id}
          value={field.value}
          onChange={handleOnChange}
          upHandler={<IncrementUp />}
          downHandler={<IncrementDown />}
          min={min}
          className={hasError ? "has-error" : ""}
        />
      </InputNumberWrapper>
      {fieldError && (
        <FormError validationTheme={validationTheme}>{fieldError}</FormError>
      )}
    </FormGroup>
  );
};
