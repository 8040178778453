import styled from "styled-components";
import { defaults, colors } from "theme";
import { formElementStyle } from "../style";

export const StyledFormLabel = styled.label`
  font-family: ${defaults.fontFamily};
  font-size: ${formElementStyle.labelFontSize};
  font-weight: ${formElementStyle.labelFontWeight};
  line-height: ${formElementStyle.labelLineHeight};
  color: ${formElementStyle.labelColor};
  margin: ${formElementStyle.labelMargin};
  white-space: pre;
  display: block;
`;

export const FormLabelContainer = styled.div`
  display: flex;
`;

export const FormLabelAsterisk = styled.span`
  color: ${colors.red};
`;

export const FormLabelTooltip = styled.span`
  margin-left: 5px;
`;