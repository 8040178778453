export const SvgNotificationWarning = () => (
  <svg
    width="18"
    height="18"
    viewBox="0 0 18 18"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="Artboard" fillRule="evenodd">
      <path
        d="M8.98913571,11.138625 C8.52113571,11.138625 8.14142143,11.5183393 8.14142143,11.9871964 C8.14142143,12.4551964 8.52113571,12.8349107 8.98913571,12.8349107 C9.45799286,12.8349107 9.83685,12.4551964 9.83685,11.9871964 C9.83685,11.5183393 9.45799286,11.138625 8.98913571,11.138625 Z M8.11570714,10.3003393 L9.82999286,10.3003393 L9.82999286,5.40176786 L8.11570714,5.40176786 L8.11570714,10.3003393 Z M9.00542143,15.7637679 C5.35827857,15.7637679 2.39085,12.7963393 2.39085,9.14919643 C2.39085,5.50205357 5.35827857,2.53548214 9.00542143,2.53548214 C12.6517071,2.53548214 15.6191357,5.50205357 15.6191357,9.14919643 C15.6191357,12.7963393 12.6517071,15.7637679 9.00542143,15.7637679 Z M9.00542143,0.821196429 C4.41285,0.821196429 0.676564286,4.556625 0.676564286,9.14919643 C0.676564286,13.7409107 4.41285,17.4780536 9.00542143,17.4780536 C13.5971357,17.4780536 17.3334214,13.7409107 17.3334214,9.14919643 C17.3334214,4.556625 13.5971357,0.821196429 9.00542143,0.821196429 Z"
        id="Fill-10"
      />
    </g>
  </svg>
);
