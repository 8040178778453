import { ReactStyle } from "types/react";

type Props = {
  style?: ReactStyle;
};

export const SvgTooltipInfo = (props: Props) => (
  <svg
    width={4}
    height={9}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M3.55 9H.516v-.429c.11-.059.223-.106.34-.143.118-.044.246-.084.386-.121V4.281l-.726-.088v-.429c.11-.044.245-.088.407-.132.168-.044.344-.08.528-.11.183-.037.363-.066.539-.088.176-.03.33-.051.462-.066h.374v4.939c.146.037.278.077.396.121.117.037.227.084.33.143V9zM1.055 1.696c0-.257.088-.458.264-.605.176-.147.403-.22.682-.22s.506.073.682.22c.176.147.264.348.264.605 0 .25-.088.447-.264.594-.176.147-.403.22-.682.22s-.506-.073-.682-.22a.733.733 0 01-.264-.594z" />
  </svg>
);
