import styled, { css } from "styled-components";
import { FormValidationThemes } from "../types";
import { defaults, colors } from "theme";

type FormErrorProps = {
  $validationTheme?: FormValidationThemes;
};

export const StyledFormError = styled.p<FormErrorProps>`
  font-family: ${defaults.fontFamily};
  font-weight: ${defaults.fontWeight};
  line-height: ${defaults.lineHeight};
  color: ${colors.red};
  font-size: 12px;
  margin: 4px 0 0;
  display: block;

  ${(props) =>
    props.$validationTheme === "floating" &&
    css`
      position: absolute;
      left: 0;
      top: 100%;
      max-width: ${defaults.width}px;
      background-color: ${colors.red};
      color: #fff;
      padding: 8px 12px;
      border-radius: ${defaults.borderRadius};
    `}
`;
