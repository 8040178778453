import { ButtonColors } from "components/Button";
import { StyledButtonLink } from "./style";

export type ButtonLinkProps = React.AnchorHTMLAttributes<HTMLAnchorElement> & {
  color?: ButtonColors;
};

export const ButtonLink = ({
  color = "blue",
  children,
  ...rest
}: ButtonLinkProps) => {
  return (
    <StyledButtonLink $color={color} {...rest}>
      {children}
    </StyledButtonLink>
  );
};
