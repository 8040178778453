import { StylesConfig, GroupBase } from "react-select";
import { defaults, colors } from "theme";
import { formElementStyle } from "components/Form/style";
import { SelectProps } from "./index";

type ReactSelectStyleProps = {
  isMulti: SelectProps["isMulti"];
  hasError: boolean;
  dropdownWidth?: number;
};

type ReactSelectStyleReturn =
  | StylesConfig<any, boolean, GroupBase<unknown>>
  | undefined;

type ReactSelectStyle = (
  props: ReactSelectStyleProps,
) => ReactSelectStyleReturn;

// Note, 'react-select' requires a library specific style object.
export const getReactSelectStyle: ReactSelectStyle = ({
  isMulti,
  hasError,
  dropdownWidth,
}) => {
  return {
    container: () => ({
      fontFamily: formElementStyle.fontFamily,
      fontSize: formElementStyle.fontSize,
      position: "relative",
      width: "100%",
    }),
    valueContainer: (provided) => ({
      // wraps singleValue, control, placeholder
      ...provided,
      fontFamily: formElementStyle.fontFamily,
      fontSize: formElementStyle.fontSize,
      padding: isMulti
        ? `1px 5px 1px ${formElementStyle.paddingHorizontal}`
        : `2px 5px 2px ${formElementStyle.paddingHorizontal}`,
    }),
    control: (provided, state) => {
      const getControlColor = () => {
        return formElementStyle.color;
      };

      const getControlBackgroundColor = () => {
        if (state.isDisabled) {
          return formElementStyle.backgroundColorDisabled;
        }
        return formElementStyle.backgroundColor;
      };

      const getControlBorderColor = () => {
        if (state.isFocused && !hasError) {
          return formElementStyle.borderColorFocus;
        }
        if (hasError || (state.isFocused && hasError)) {
          return colors.red;
        }
        if (state.isDisabled) {
          return formElementStyle.borderColorDisabled;
        }
        return formElementStyle.borderColor;
      };

      const getControlBoxShadow = () => {
        if (state.isFocused && !hasError) {
          return `${formElementStyle.boxShadow} ${formElementStyle.boxShadowColorFocus}`;
        }
        if (state.isFocused && hasError) {
          return `${formElementStyle.boxShadow} ${formElementStyle.boxShadowColorError}`;
        }
        return "none";
      };

      const getControlHover = () => {
        if (!state.isFocused && !state.isDisabled && !hasError) {
          return {
            borderColor: formElementStyle.borderColorHover,
          };
        }
        if (hasError && !state.isFocused) {
          return {
            boxShadow: `${formElementStyle.boxShadow} ${formElementStyle.boxShadowColorHover}`,
          };
        }
        return {};
      };

      return {
        ...provided,
        fontFamily: formElementStyle.fontFamily,
        fontSize: formElementStyle.fontSize,
        color: getControlColor(),
        backgroundColor: getControlBackgroundColor(),
        borderColor: getControlBorderColor(),
        borderRadius: formElementStyle.borderRadius,
        borderStyle: formElementStyle.borderStyle,
        borderWidth: 1,
        cursor: state.isDisabled ? "not-allowed" : "pointer",
        height: isMulti ? "auto" : formElementStyle.height,
        minHeight: isMulti ? formElementStyle.height : "auto",
        outline: "0",
        position: "relative",
        boxShadow: getControlBoxShadow(),
        transition: `border-color ${defaults.transition}, box-shadow ${defaults.transition}, background-color ${defaults.transition}`,
        "&:hover": getControlHover(),

        input: {
          fontFamily: formElementStyle.fontFamily,
        },

        svg: {
          fill: formElementStyle.iconColor,
        },
      };
    },
    singleValue: (provided, state) => ({
      // selected value state
      ...provided,
      fontFamily: formElementStyle.fontFamily,
      fontSize: formElementStyle.fontSize,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      color: formElementStyle.color,
      margin: 0,
      padding: 0,
    }),
    input: (provided) => ({
      ...provided,
      margin: "0",
      padding: "0",
    }),
    placeholder: (provided) => ({
      ...provided,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
      width: "100%",
      padding: 0,
      margin: 0,
      color: formElementStyle.placeholderColor,
    }),
    noOptionsMessage: (provided) => ({
      ...provided,
      fontFamily: formElementStyle.fontFamily,
      fontSize: 13,
      fontWeight: 500,
      lineHeight: 1.5,
      color: "#7F7E91",
      padding: "5px 20px",
      textAlign: "left",
      margin: "0",
    }),
    menu: (provided) => ({
      ...provided,
      // wraps menuList
      borderRadius: defaults.borderRadius,
      background: colors.bg,
      border: "0",
      boxShadow: "0 2px 10px 0 rgba(0, 0, 0, 0.2)",
      ...(typeof dropdownWidth === "number" && {
        width: dropdownWidth,
      }),
    }),
    menuList: (provided) => ({
      ...provided,
      padding: "10px 0",
    }),
    option: (provided, state) => {
      const getOptionBackgroundColor = () => {
        if (state.isSelected) {
          return "rgba(255, 255, 255, 0.2)";
        }
        if (state.isFocused) {
          return "rgba(255, 255, 255, 0.1)";
        }
        return "transparent";
      };

      const getOptionColor = () => {
        if (state.isDisabled) {
          return "#ccc";
        }
        if (state.isSelected) {
          return "#fff";
        }
        return "#fff";
      };

      return {
        ...provided,
        fontFamily: formElementStyle.fontFamily,
        fontSize: 14,
        fontWeight: 500,
        lineHeight: 1.5,
        padding: "8px 13px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        transition: `all ${defaults.transition}`,
        backgroundColor: getOptionBackgroundColor(),
        color: getOptionColor(),
        cursor: "pointer",

        "&:active": {
          // override default active value provided
          background: colors.listHover,
        },
      };
    },
    indicatorsContainer: () => ({
      display: "flex",
      alignItems: "center",
      flex: "none",
      cursor: "pointer",
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    group: () => ({
      paddingBottom: "6px",
    }),
    groupHeading: () => ({
      fontFamily: defaults.fontFamily,
      fontSize: 11,
      fontWeight: 500,
      lineHeight: "1.4",
      padding: "5px 20px",
      color: colors.formLabel,
    }),
  };
};
