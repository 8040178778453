import styled from "styled-components";
import { defaults, colors } from "theme";
import { FormStyleProps } from "../types";
import { formElementBaseStyle, inputHeight } from "../style";

const controlsWidth = 80;

export const InputNumberWrapper = styled.div<FormStyleProps>`
  .rc-input-number {
    position: relative;
  }

  .rc-input-number-input {
    ${formElementBaseStyle};
    padding-right: ${controlsWidth}px;
  }

  .rc-input-number-handler-wrap {
    display: flex;
    flex-direction: column;
    position: absolute;
    right: 0;
    top: 0;
    bottom: 0;
  }

  .rc-input-number-handler {
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${controlsWidth}px;
    height: ${inputHeight / 2}px;
    border: 1px solid ${colors.border};
    cursor: pointer;

    &:hover {
      transition: ${defaults.transitionFull};
      border-color: #fff;
    }
  }

  .rc-input-number-handler-up {
    border-top-right-radius: ${defaults.borderRadius};
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
  }

  .rc-input-number-handler-down {
    border-bottom-right-radius: ${defaults.borderRadius};
    border-bottom-color: transparent;
    border-right-color: transparent;
  }

  .rc-input-number-input-wrap {
    width: 100%;
  }

  .increment-icon {
    font-variation-settings: "OPSZ" 20, "wght" 200;
    width: 20px;
    height: 20px;
  }
`;
