import { ReactChildren, ReactStyle } from "types/react";
import { Loader } from "components/Loader";
import { StyledFormGroup } from "./style";

type FormGroupProps = {
  isLoading?: boolean;
  style?: ReactStyle;
  children?: ReactChildren;
  extraClassName?: string;
};

export const FormGroup = ({
  isLoading,
  children,
  extraClassName,
  ...rest
}: FormGroupProps) => {
  const getClassName = () => {
    let className = "formGroup";
    if (typeof extraClassName === "string") {
      className += ` ${extraClassName}`;
    }
    return className;
  };
  return (
    <StyledFormGroup className={getClassName()} {...rest}>
      {children}
      {isLoading ? <Loader variant="absolute" /> : null}
    </StyledFormGroup>
  );
};
