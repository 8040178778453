import { ReactStyle } from "types/react";
import { SvgNotificationSuccess } from "svg/SvgNotificationSuccess";
import { SvgNotificationError } from "svg/SvgNotificationError";
import { SvgNotificationWarning } from "svg/SvgNotificationWarning";
import { SvgNotificationInfo } from "svg/SvgNotificationInfo";
import {
  NotificationTitle,
  NotificationWrapper,
  NotificationMessage,
} from "./style";

export type NotificationColorProps = "green" | "orange" | "red";
export type NotificationProps = {
  title: string;
  message?: string;
  color?: NotificationColorProps;
  style?: ReactStyle;
};

export const Notification = ({
  title,
  message,
  color,
  ...rest
}: NotificationProps) => {
  const styleProps = { $color: color };

  const renderIcon = () => {
    switch (color) {
      case "green":
        return <SvgNotificationSuccess />;
      case "orange":
        return <SvgNotificationError />;
      case "red":
        return <SvgNotificationWarning />;
      default:
        return <SvgNotificationInfo />;
    }
  };

  return (
    <NotificationWrapper {...styleProps} {...rest}>
      <NotificationTitle {...styleProps}>
        {renderIcon()}
        {title}
      </NotificationTitle>
      {message ? <NotificationMessage>{message}</NotificationMessage> : null}
    </NotificationWrapper>
  );
};
