import { ReactStyle } from "types/react";
import { LoaderSpinner } from "./LoaderSpinner";
import { LoaderContainer } from "./style";

export type LoaderProps = {
  variant?: "inline" | "block" | "absolute" | "fixed" | "fixedCenter";
  backdrop?: "none" | "transparent" | "opaque";
  size?: "small" | "large";
  style?: ReactStyle;
};

export const Loader = ({
  variant = "inline",
  backdrop = "none",
  size = "large",
  ...rest
}: LoaderProps) => {
  return (
    <LoaderContainer
      $variant={variant}
      $backdrop={backdrop}
      $size={size}
      aria-busy="true"
      aria-live="polite"
      className="loader"
      {...rest}
    >
      <LoaderSpinner size={size} />
    </LoaderContainer>
  );
};
