// import { useAuth0 } from "@auth0/auth0-react";
// import { Loader } from "components/Loader";
// import { Notification } from "components/Notification";
// import { Container } from "pages/GiftSuggestions/style";
import Routes from "routing/Routes";
// import Login from "pages/Login";

function App() {
  // const { isLoading, isAuthenticated, error } = useAuth0();

  // if (isLoading) {
  //   return <Loader variant="fixed" backdrop="transparent" />;
  // }

  // if (error) {
  //   return (
  //     <Container>
  //       <Notification
  //         title="Error"
  //         message={error.message}
  //         color="red"
  //         style={{ margin: "30px 0" }}
  //       />
  //     </Container>
  //   );
  // }

  // function checkSession() {
  //   if (isAuthenticated) {
  //     return <Routes />;
  //   } else {
  //     return <Login />;
  //   }
  // }

  // return checkSession();

  // Auth is disabled. Allow users to skip directly to routes
  return <Routes />;
}

export default App;
