import { ButtonContainer } from "components/Button";
import { ButtonLink } from "components/ButtonLink";
import { Img } from "components/Img";
import { GiftSuggestionResponse } from "../types";
import {
  Container,
  Heading,
  Result,
  ResultHeading,
  ResultSubHeading,
  ResultText,
  ResultImgContainer,
  ResultCta,
  ResultCtaHeading,
} from "./style";
import { Props } from "./types";

export function Results({
  giftSuggestions,
  sendGiftBuyNowNotification,
}: Props) {
  function getDeliveryValue(delivery: GiftSuggestionResponse["delivery1"]) {
    if (typeof delivery === "string") {
      return delivery === "Free" || delivery === "Unknown"
        ? delivery
        : `$${delivery}`;
    } else if (typeof delivery === "number") {
      return `$${delivery.toString()}`;
    } else {
      return "";
    }
  }

  function onClickBuyNow({ title, link }: { title: string; link: string }) {
    console.log("onClickBuyNow");
    sendGiftBuyNowNotification({ title, link });
  }

  if (giftSuggestions.length) {
    return (
      <Container>
        <Heading>Gift Recommendations</Heading>
        {giftSuggestions.map((giftSuggestion) => {
          const title = giftSuggestion.title;
          const link1 = giftSuggestion.link1;
          const thumbnail1 = giftSuggestion.thumbnail1;
          const price1 = giftSuggestion.price1;
          const delivery1 = giftSuggestion.delivery1;
          return (
            <Result key={giftSuggestion.id}>
              <ResultHeading>{title}</ResultHeading>
              <ResultSubHeading>Description</ResultSubHeading>
              <ResultText>{giftSuggestion.description}</ResultText>
              <ResultSubHeading>Why buy this?</ResultSubHeading>
              <ResultText>{giftSuggestion.reason}</ResultText>
              {thumbnail1 ? (
                <ResultImgContainer>
                  <Img src={thumbnail1} alt={title} />
                </ResultImgContainer>
              ) : null}
              {price1 || delivery1 ? (
                <ResultCta>
                  {typeof price1 === "number" ? (
                    <ResultCtaHeading>
                      <span>Price:</span> ${price1.toString()}
                    </ResultCtaHeading>
                  ) : null}
                  {delivery1 ? (
                    <ResultCtaHeading>
                      <span>Delivery:</span> {getDeliveryValue(delivery1)}
                    </ResultCtaHeading>
                  ) : null}
                </ResultCta>
              ) : null}
              {link1 ? (
                <ButtonContainer>
                  <ButtonLink
                    href={link1}
                    onClick={() =>
                      onClickBuyNow({
                        title,
                        link: link1,
                      })
                    }
                    target="_blank"
                  >
                    Buy Now
                  </ButtonLink>
                </ButtonContainer>
              ) : null}
            </Result>
          );
        })}
      </Container>
    );
  } else {
    return null;
  }
}
