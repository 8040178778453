import { SvgModalClose } from "svg/SvgModalClose";
import { ModalClose as ModalCloseContainer } from "./style";

export type ModalProps = {
  onClick: () => void;
};

export function ModalClose({ onClick }: ModalProps) {
  return (
    <ModalCloseContainer onClick={onClick} aria-label="Close" tabIndex={-1}>
      <SvgModalClose />
    </ModalCloseContainer>
  );
}
