import styled from "styled-components";
import { defaults } from "../../theme";

export const StyledToggle = styled.div`
  border-radius: ${defaults.borderRadius};
  border-width: 2px;
  border-style: solid;
  border-color: #fff;
  height: 48px;
  width: 100%;
  display: flex;
`;

export const ToggleOption = styled.button<{ $isActive: boolean }>`
  font-family: ${defaults.fontFamily};
  font-size: 16px;
  font-weight: 700;
  line-height: 1.5;
  color: ${(props) => (props.$isActive ? "#191919" : "#fff")};
  text-decoration: none;
  text-align: center;
  background-color: ${(props) => (props.$isActive ? "#fff" : "transparent")};
  border: 0;
  width: 50%;
  position: relative;
  padding: 0 12px;
  outline: 0;
  cursor: ${(props) => (props.$isActive ? "default" : "pointer")};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: ${defaults.transitionFull};

  &:hover {
    color: ${(props) =>
      props.$isActive ? "#191919" : "rgba(255, 255, 255, 0.5)"};
  }
`;
