import React from "react";
import { ReactChildren } from "types/react";
import { FormValidationThemes } from "../types";
import { StyledFormError } from "./style";

export type FormErrorProps = {
  children: ReactChildren;
  validationTheme?: FormValidationThemes;
};

export const FormError = ({ validationTheme, children }: FormErrorProps) => {
  const renderMessage = () => {
    if (
      children &&
      typeof children === "object" &&
      Object.keys(children).length
    ) {
      // If children is received as an object.
      return Object.entries(children).map(([key, value], index) => {
        return (
          <React.Fragment key={key}>
            {index > 0 ? <br /> : null}
            {value}
          </React.Fragment>
        );
      });
    }
    return children;
  };

  return (
    <StyledFormError $validationTheme={validationTheme} className="formError">
      {renderMessage()}
    </StyledFormError>
  );
};
